<template>
  <section class="section" id="gerenciar-orcamentos">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-primary">
            Gerenciamento de Orçamentos
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <router-link
            to="/admin/cadastrar-orcamento"
            class="button is-primary is-fullwidth"
          >
            <b-icon icon="plus" class="mr-1" />
            Cadastrar Orçamento
          </router-link>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-half">
          <b-field label="Hotel">
            <b-select v-model="filtro.hotelId" expanded>
              <option v-for="hotel in hoteis" :key="hotel.id" :value="hotel.id">
                {{ hotel.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Usuário">
            <b-select
              v-model="filtro.usuarioId"
              expanded
              @input="handleSelectUsuario"
            >
              <option
                v-for="usuario in usuarios"
                :key="usuario.id"
                :value="usuario.id"
              >
                {{ usuario.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Data Cadastro">
            <b-datepicker
              v-model="filtro.dataI"
              placeholder="Data Entrada"
              icon="calendar-today"
              trap-focus
              expanded
              :disabled="!searchByDate"
            />
            <b-datepicker
              v-model="filtro.dataF"
              placeholder="Data Saída"
              icon="calendar-today"
              trap-focus
              expanded
              :disabled="!searchByDate"
            />
            <p class="control">
              <b-button
                :icon-left="searchByDate ? 'lock-open' : 'lock'"
                :type="searchByDate ? 'is-success' : 'is-danger'"
                @click="() => (searchByDate = !searchByDate)"
              />
            </p>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="CPF">
            <b-input type="text" v-model="filtro.cpf" expanded />
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Nome">
            <b-input type="text" v-model="filtro.nome" expanded />
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Telefone">
            <b-input type="tel" v-model="filtro.telefone" expanded />
          </b-field>
        </div>
        <div class="column is-half">
          <b-switch
            v-model="isRespondido"
            :disabled="isConvertido || !!filtro.usuarioId"
          >
            Respondido
          </b-switch>
          <b-switch v-model="isConvertido">
            Convertido
          </b-switch>
        </div>
        <div
          class="column is-half"
          style="display: flex;justify-content: flex-end;"
        >
          <b-button
            class="mr-2"
            type="is-light"
            expanded
            @click="limparFiltros"
          >
            Limpar
          </b-button>
          <b-button
            type="is-secondary"
            expanded
            :disabled="!filtro.dataI || !filtro.dataF"
            @click="getOrcamentos"
          >
            Buscar
          </b-button>
        </div>
      </div>

      <section
        v-if="orcamentos.length === 0 && !isLoading"
        class="section has-background-light has-text-centered"
        style="border-radius: 10px"
      >
        Nenhum resultado encontrado para a sua busca.
      </section>
      <b-table
        v-else
        :data="orcamentos"
        :loading="isLoading"
        hoverable
        mobile-cards
      >
        <b-table-column field="nome" label="Nome" centered v-slot="props">
          {{ props.row.nome }}&nbsp;{{ props.row.sobrenome }}
        </b-table-column>

        <b-table-column
          field="telefone"
          label="Telefone"
          centered
          v-slot="props"
        >
          {{ props.row.telefone }}
        </b-table-column>

        <b-table-column field="periodo" label="Periodo" v-slot="props" centered>
          {{ props.row.dataEntrada | maskDate }} à
          {{ props.row.dataSaida | maskDate }}
        </b-table-column>

        <b-table-column
          field="dataCadastro"
          label="Data Cadastro"
          v-slot="props"
          centered
        >
          {{ props.row.dataCadastro | maskDate }}
        </b-table-column>

        <b-table-column
          field="quantidadeAdultos"
          label="Qtd. Adultos"
          centered
          numeric
          v-slot="props"
        >
          {{ props.row.quantidadeAdultos }}
        </b-table-column>

        <b-table-column
          field="quantidadeCriancas"
          label="Qtd. Criancas"
          centered
          numeric
          v-slot="props"
        >
          {{ props.row.quantidadeCriancas }}
        </b-table-column>

        <b-table-column field="venda" label="Venda" v-slot="props" centered>
          {{ props.row.vendaId || '-' }}
        </b-table-column>

        <b-table-column field="usuario" label="Usuario" v-slot="props" centered>
          {{ props.row.usuario ? props.row.usuario.nome : '-' }}
        </b-table-column>

        <b-table-column field="acoes" label="Ações" v-slot="props">
          <b-icon
            class="is-clickable"
            title="Editar"
            icon="pencil"
            @click.native="editOrcamento(props)"
          />
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import hoteisService from '@/services/hoteisService';
import usuarioService from '@/services/usuarioService';
import orcamentoService from '@/services/orcamentoService';

export default {
  name: 'GerenciarOrcamento',
  data() {
    return {
      filtro: {
        dataI: new Date(),
        dataF: new Date(
          dayjs()
            .add(7, 'day')
            .format('YYYY-MM-DD'),
        ),
      },
      hoteis: [],
      usuarios: [],
      orcamentos: [],
      isRespondido: false,
      isConvertido: false,
      isLoading: false,
      searchByDate: false,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getHoteis();
    await this.getUsuarios();
    await this.getOrcamentos();
  },
  methods: {
    async getHoteis() {
      return hoteisService.get().then(({ data }) => {
        this.hoteis = data;
        if (this.hoteis.length > 0) {
          const [hotel] = this.hoteis;
          this.filtro.hotelId = hotel.id;
        }
      });
    },
    async getUsuarios() {
      return usuarioService.get().then(({ data }) => {
        this.usuarios = data;
      });
    },
    getOrcamentos() {
      this.isLoading = true;
      const filtro = { ...this.filtro };
      filtro.dataI = dayjs(filtro.dataI).format('YYYY-MM-DD');
      filtro.dataF = dayjs(filtro.dataF).format('YYYY-MM-DD');
      filtro.respondido = this.isRespondido;
      filtro.convertido = this.isConvertido;

      if (!this.searchByDate) {
        delete filtro.dataI;
        delete filtro.dataF;
      }

      orcamentoService
        .search(filtro)
        .then(({ data }) => {
          this.orcamentos = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSelectUsuario(usuario) {
      if (usuario) {
        this.isRespondido = true;
      }
    },
    limparFiltros() {
      this.filtro = {};
      if (this.hoteis.length > 0) {
        const [hotel] = this.hoteis;
        this.filtro.hotelId = hotel.id;
      }
      this.isRespondido = false;
      this.isConvertido = false;
      this.orcamentos = [];
    },
    editOrcamento({ row }) {
      this.$router.push(`/admin/editar-orcamento/${row.id}`);
    },
  },
};
</script>
